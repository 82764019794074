import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import logo from "../img/logo-black.svg";
import scrollTo from "gatsby-plugin-smoothscroll";
import EnquiryForm from "../components/enquiry";
import { useHubspotForm } from "@aaronhayes/react-use-hubspot-form";
import ContactUsBanner from "../img/contact-us.jpg";
export default function ContactUs() {
  const { loaded, error, formCreated } = useHubspotForm({
    region: "na1",
    portalId: "19573488",
    formId: "1bc4eac9-1a8c-476e-8e4f-58cff8896631",
    target: "#my-hubspot-form-new",
  });
  return (
    <div>
      <Helmet>
        <title>OIC - Privacy Policy</title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta name="title" content="OiC" />
        <meta name="description" content="OiC Apps Website" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta property="og:description" content="OiC Apps Website" />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
      <div
         style={{padding:"90px 0", background:"#000"}}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12 ">
                <h1
                  style={{
                   textAlign:"center",
                    position: "absolute",
                    color:"#fff"
                  }}
                >
                  Privacy & Policy
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div >
          <div className="container">
            <div className="row ">
             <br></br><br></br>
              <p>
                The Company is concerned with and committed to protecting and
                preserving the privacy of all of its Users, and has released
                this privacy policy statement (the &quot;Privacy Policy&quot; or
                &quot;PP&quot;) to notify You of how the Company gathers,
                stores, uses, discloses, modifies, and/or deletes Your
                personally identifiable information (&quot;PII&quot;) collected
                during your use. Any capitalized words used, but not specified
                in this PP will have the sense ascribed to them in the
                above-mentioned Terms and Conditions.
              </p>
              <ol>
                <li>
                  <strong>Purpose</strong>
                </li>
                <ol>
                  <li>The purpose of this PP is to ensure that:</li>
                  <ol>
                    <li>
                      You are aware of and have complete control over what
                      Customer Data you provide to the Company, and how the same
                      is and will be used by the Company.
                    </li>
                    <li>
                      You are at all times aware of the rights available to you
                      with respect to your Customer Data.
                    </li>
                    <li>
                      The information provided by you is processed, stored, and
                      used in accordance with the applicable laws, including
                      with respect to maintaining appropriate data protection
                      and privacy infrastructure.
                    </li>
                  </ol>
                  <li>
                    We insist upon the highest standards for securing Customer
                    Data and maintaining customer information privacy.
                  </li>
                </ol>
                <li>
                  <strong>Information We Collect</strong>
                </li>
                <ol>
                  <li>
                    We have already specified (under points 4 and 5 of the ToS)
                    the kind of Customer Data (PII and otherwise) that we
                    require, collect, access, and store during the period of
                    account creation and thereafter during your usage of the
                    OIC. Both You and your End-Users (through you) have the
                    right, at any time, to choose not to provide us with any of
                    this information and to ask us to delete the same, if
                    required. However, doing so may result in the subsequent
                    suspension/hampering of all or a part of OIC.
                  </li>
                  <li>
                    Cookies: Cookies are pieces of information that are stored
                    on Your computer when You access the Platform. The Platform
                    may use temporary cookies to help you access some of the
                    special functions within the database driven areas of the
                    Platform. Once you leave our Platform, these cookies expire.
                    Cookies do not collect personal identifying information
                    about the user.
                  </li>
                </ol>
                <li>
                  <strong>Information Storage and Security</strong>
                </li>
                <ol>
                  <li>
                    All the information that we collect from You during your
                    usage of OIC is stored on third-party servers. We access the
                    information in the cloud. Our third-party service provider
                    uses globally accepted standards for ensuring and
                    maintaining the protection, security, confidentiality, and
                    privacy of all data on its servers.
                  </li>
                  <li>
                    We work hard to protect You from unauthorized access or
                    unauthorized alteration, disclosure or destruction of any
                    Customer Data we hold. Pursuant to the same, we have
                    implemented globally accepted standards and procedures to
                    maintain ongoing data protection and privacy by ensuring the
                    following:
                  </li>
                  <ol>
                    <li>
                      The encryption of all data (both Customer Data and OIC
                      Content) using secure server software, which is among the
                      best software available today for secure transactions.
                    </li>
                    <li>
                      The pseudonymization of all data collected by us, by
                      ensuring that all PII is kept/stored separately from all
                      the remaining information, to ensure that the remaining
                      data cannot be attributed to an identified or identifiable
                      natural person, unless actively merged or joined with the
                      PII.
                    </li>
                    <li>
                      The constant review of our information collection, storage
                      and processing practices, including this ToS, PP, and the
                      physical security measures to guard against unauthorized
                      access to systems.
                    </li>
                    <li>
                      The constant upgradation and/or alteration of our policies
                      may be necessary to ensure the ongoing confidentiality,
                      protection, privacy, security, and accessibility of all
                      data and systems.
                    </li>
                    <li>
                      Limiting the disclosure of PII to Our employees,
                      independent contractors including vendors, affiliates,
                      consultants, business associates, service providers and
                      distributors of Our services, only on a
                      &ldquo;need-to-know&rdquo; basis, and only if the
                      disclosure will enable that person/entity to provide Us
                      with business, professional, or technical support or
                      fulfill Your request and requirements under the services.
                    </li>
                  </ol>
                </ol>
                <li>
                  <strong>Information We Share</strong>
                </li>
                <ol>
                  <li>
                    In addition to any third-party service providers who,
                    subject to your prior consent, we may share some or all
                    parts of the Customer Data with (as is captured under point
                    6 of the ToS), we may disclose any information provided by
                    You on the Platform as may be deemed to be necessary or
                    appropriate: (a) under applicable law, including laws
                    outside Your country of residence; (b) to comply with legal
                    process; (c) to respond to requests from public and
                    government authorities including public and government
                    authorities outside Your country of residence; (d) to
                    enforce Our Terms of Use; or (e) to allow Us to pursue
                    available remedies or limit the damages that We may sustain.
                  </li>
                  <li>
                    By agreeing to this PP, you also explicitly understand and
                    consent to the fact that we may share Customer Data with
                    other corporate entities and affiliates only for the
                    purposes of:
                  </li>
                  <ol>
                    <li>
                      Using their assistance to detect and prevent identity
                      theft, fraud and other potentially illegal acts; or
                    </li>
                    <li>
                      Correlating related or multiple accounts to prevent abuse
                      of the OIC Platform.
                    </li>
                  </ol>
                </ol>
                <li>
                  <strong>Rights Available to You</strong>
                </li>
                <ol>
                  <li>
                    Once you start using the OIC platform, both you and your
                    End-Users (together the &ldquo;Data Subjects&rdquo;) shall
                    have the below mentioned rights. If any of your End-Users
                    wish to exercise the below rights, it will be your
                    responsibility to receive the relevant written request from
                    them, and then communicate/transfer the same to us so that
                    we may take the appropriate action:
                  </li>
                  <ol>
                    <li>
                      Delete/erase all PII (or all data) pertaining to a Data
                      Subject that we may have on our systems. This provision
                      will not apply to any data or PII that we may need to
                      retain pursuant to any applicable law or any
                      request/requirement of a public/government body, whether
                      in your country of residence or not;
                    </li>
                    <li>
                      Rectify, modify, or alter any of the PII or other data
                      pertaining to a Data Subject, if you have identified any
                      mistake, error or inaccuracy in the same.
                    </li>
                    <li>
                      Restrict or limit the manner and ways in which a
                      particular Data Subject's information/data is processed or
                      used by us;
                    </li>
                    <li>
                      Export and provide a Data Subject with a copy of all
                      his/her data, including the PII, that we may have on our
                      servers. This includes all data and server logs pertaining
                      to activities and behaviour on the Platform as well.
                    </li>
                  </ol>
                  <li>
                    We will make all commercially reasonable best efforts to
                    comply with all of the above requests (when received) as
                    soon as possible. If as a Customer, you wish to exercise any
                    of your above rights, or if you wish to convey a request on
                    behalf of an End-User, kindly send an email to
                    marketing@oicapps.com.
                  </li>
                </ol>
                <li>
                  <strong>Change in Privacy Policy</strong>
                  <br />
                  <br />
                  You acknowledge that becoming a user of the OIC platform
                  signifies your assent to this PP. In the event there are any
                  changes to this PP or in the way we treat any Customer Data,
                  we shall intimate you of the same via email at least 1 week
                  prior to the enforcement of such change. We will also display
                  a notice on the Platform regarding the above. If after your
                  receipt of a notice of change to the ToS or PP you do not
                  object to the same and/or continue to use the OIC platform,
                  you will be assumed to have provided your consent to the
                  changes.
                </li>
                <li>
                  <strong>Questions</strong>
                  <br />
                  <br />
                  Questions regarding this Privacy Policy should be directed to
                  the following email ID: marketing@oicapps.com, or to the
                  following Data Protection Officer:
                  <br />
                  Name: Vinoth
                  <br />
                  Email ID: marketing@oicapps.com
                  <br />
                  Phone Number: +91 9791836596
                </li>
                <li>
                  <strong>Disclaimer</strong>
                </li>
                <ol>
                  <li>
                    We shall not be liable for any loss or damage sustained by
                    reason of any disclosure (inadvertent or otherwise) of any
                    Customer Data, if the same is either (a) required under
                    point 4.1 above; or (b) was effected through no fault, act,
                    or omission of the Company.
                  </li>
                  <li>
                    We reserve our right to offer our services to any other
                    client/prospective client without restriction.
                  </li>
                  <li>
                    By registering or by using the Platform, you explicitly
                    accept, without limitation or qualification, the collection,
                    use and transfer of the Customer Data in the manner
                    described herein.
                  </li>
                  <li>
                    Please read this Privacy Policy carefully as it affects your
                    rights and liabilities under the law.
                  </li>
                </ol>
              </ol>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
